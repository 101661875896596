import { Route, Routes } from 'react-router-dom';
import { SignIn } from './pages/Signin';
import { SignUp } from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Courses from './pages/Courses';
import Students from './pages/Students';
import ProtectedRoute from './components/route/protected-route';
import NoAuthRoute from './components/route/no-auth-route';

export function Router() {
	return (
		<Routes>
			<Route
				path="/sign-in"
				element={
					<NoAuthRoute>
						<SignIn />
					</NoAuthRoute>
				}
			/>
			<Route
				path="/sign-up"
				element={
					<NoAuthRoute>
						<SignUp />
					</NoAuthRoute>
				}
			/>
			<Route
				path="/"
				element={
					<ProtectedRoute>
						<Dashboard />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/courses"
				element={
					<ProtectedRoute>
						<Courses />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/students"
				element={
					<ProtectedRoute>
						<Students />
					</ProtectedRoute>
				}
			/>
		</Routes>
	);
}
