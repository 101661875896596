import PropTypes from 'prop-types';
import RightPanel from '../common/RightPanel';
import ActivityItems from './activities/activity.items';

export default function CoursesPanel({ showRightPanel, activities }) {
	return (
		<RightPanel show={showRightPanel}>
			<ActivityItems activities={activities} />
		</RightPanel>
	);
}

CoursesPanel.propTypes = {
	showRightPanel: PropTypes.bool.isRequired,
	activities: PropTypes.array.isRequired,
};
