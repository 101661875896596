import * as Yup from 'yup';
import { USER_TYPES } from '../signup.resources';

const form0InitialValues = {
	user_type: USER_TYPES[0].value,
};

const form0ValidationSchema = Yup.object({
	user_type: Yup.string().required('This field is required'),
});

const form0Errors = {
	user_type: '',
};

const form0Touched = {
	user_type: false,
};

export { form0InitialValues, form0ValidationSchema, form0Errors, form0Touched };
