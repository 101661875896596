import { BiSolidDashboard } from 'react-icons/bi';
import { LuLayoutDashboard } from 'react-icons/lu';
import { IoIosBookmarks } from 'react-icons/io';
import { PiBookBookmark, PiStudent, PiStudentFill } from 'react-icons/pi';

const NAV_ITEMS = [
	{
		id: 1,
		title: 'Dashboard',
		route: '/',
		activeIcon: <BiSolidDashboard color="white" size="1.75rem" />,
		icon: <LuLayoutDashboard size="1.75rem" />,
	},
	{
		id: 2,
		title: 'Coursework',
		route: '/courses',
		activeIcon: <IoIosBookmarks color="white" size="1.75rem" />,
		icon: <PiBookBookmark size="1.75rem" />,
	},
	{
		id: 3,
		title: 'My Students',
		route: '/students',
		activeIcon: <PiStudentFill color="white" size="1.75rem" />,
		icon: <PiStudent size="1.75rem" />,
	},
];

export default NAV_ITEMS;
