import { useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import {
	form1Errors,
	form1InitialValues,
	form1Touched,
	form1ValidationSchema,
} from '../components/sign-up/formik/formik.form1';
import {
	form2Errors,
	form2InitialValues,
	form2Touched,
	form2ValidationSchema,
} from '../components/sign-up/formik/formik.form2';
import {
	form3Errors,
	form3InitialValues,
	form3Touched,
	form3ValidationSchema,
} from '../components/sign-up/formik/formik.form3';
import {
	form4Errors,
	form4InitialValues,
	form4Touched,
	form4ValidationSchema,
} from '../components/sign-up/formik/formik.form4';
import {
	form0Errors,
	form0InitialValues,
	form0Touched,
	form0ValidationSchema,
} from '../components/sign-up/formik/formik.form0';
import moment from 'moment';
import { useAuth } from '../context/authentication';
import { fetchToken } from '../services/authentication';
import { createBulkUserInfo } from '../services/users';
import { useNavigate } from 'react-router-dom';

const useSignUp = () => {
	const navigate = useNavigate();
	const { registerUser } = useAuth();
	const [currentStep, setCurrentStep] = useState(0);
	const [formData, setFormData] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleFormSubmit = (formik, nextStep) => {
		formik.validateForm().then((errors) => {
			if (Object.keys(errors).length === 0) {
				setFormData({ ...formData, ...formik.values });
				setCurrentStep(nextStep);
			} else {
				toast.error('Please fill all required fields or clear any errors.');
			}
		});
	};

	const formik0 = useFormik({
		initialValues: form0InitialValues,
		validationSchema: form0ValidationSchema,
		initialErrors: form0Errors,
		initialTouched: form0Touched,
		onSubmit: () => handleFormSubmit(formik0, 1),
	});

	const formik1 = useFormik({
		initialValues: form1InitialValues,
		validationSchema: form1ValidationSchema,
		initialErrors: form1Errors,
		initialTouched: form1Touched,
		onSubmit: () => handleFormSubmit(formik1, 2),
	});

	const formik2 = useFormik({
		initialValues: form2InitialValues,
		validationSchema: form2ValidationSchema,
		initialErrors: form2Errors,
		initialTouched: form2Touched,
		onSubmit: () => handleFormSubmit(formik2, 3),
	});

	const formik3 = useFormik({
		initialValues: form3InitialValues,
		validationSchema: form3ValidationSchema,
		initialErrors: form3Errors,
		initialTouched: form3Touched,
		onSubmit: () => handleFormSubmit(formik3, 4),
	});

	const formik4 = useFormik({
		initialValues: form4InitialValues,
		validationSchema: form4ValidationSchema,
		initialErrors: form4Errors,
		initialTouched: form4Touched,
		onSubmit: (values) => {
			const userData = {
				password: formik1.values.password,
				email: formik1.values.email,
				firstName: formik1.values.name.split(' ')[0],
				lastName: formik1.values.name.split(' ').slice(1).join(' '),
				dateOfBirth: moment(formik1.values.dob).format('YYYY-MM-DD'),
				nationality: formik2.values.nationality,
				phoneNumber: formik1.values.phone_number,
			};

			const userInfo = [
				{
					question: 'Planned field of study',
					answer: formik2.values.field_of_study,
				},
				{
					question: 'Level you are applying for',
					answer: formik2.values.current_level,
				},
				{
					question: 'Highest level of education',
					answer: formik2.values.highest_level,
				},
				{
					question: 'Name of High School',
					answer: formik3.values.name_of_high_school,
				},
				{
					question: 'High school graduation date',
					answer: moment(formik3.values.high_school_graduation_date).format(
						'YYYY-MM-DD'
					),
				},
				{
					question: 'Final/aniticipated High school grade',
					answer: formik3.values.high_school_grade,
				},
				{
					question: 'Which school are you considering?',
					answer: formik3.values.school_prospect,
				},
				{
					question: 'Describe the nature of your main internet connection',
					answer: formik4.values.nature_of_internet,
				},
				{
					question: 'Have you applied to study abroad before?',
					answer: formik4.values.applied_abroad,
				},
				{
					question: 'How well do you know the application process?',
					answer: formik4.values.application_knowledge,
				},
				{
					question: 'Do you need help with the application process?',
					answer: formik4.values.need_help_in_application,
				},
			];

			let userID;

			setIsSubmitting(true);
			// First, we need to call the registerUser function from the useAuth hook to register the user
			registerUser(userData, formik0.values.user_type.toLowerCase())
				.then((res) => {
					// Next, we need to set the user ID in state
					userID = res.data.id;
					// Then, we need to authenticate to get the user token for the next steps
					return fetchToken(formik1.values.email, formik1.values.password);
				})
				.then((res) => {
					// We need to update user information
					return createBulkUserInfo(userID, userInfo, res.data.jwt);
				})
				.then(() => {
					toast.success(
						'User account created successfully. Please sign in to continue.'
					);
					localStorage.clear();
					navigate('/sign-in');
				})
				.catch((error) => {
					console.error('User registration error:', error);
					toast.error(error?.response?.data?.message || 'An error occurred');
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		},
	});

	const getCurrentFormik = () => {
		switch (currentStep) {
			case 0:
				return formik0;
			case 1:
				return formik1;
			case 2:
				return formik2;
			case 3:
				return formik3;
			case 4:
				return formik4;
			default:
				return formik1;
		}
	};

	return {
		currentStep,
		setCurrentStep,
		formik0,
		formik1,
		formik2,
		formik3,
		formik4,
		getCurrentFormik,
		isSubmitting,
	};
};

export default useSignUp;

// "id": 3,
// 	"username": "test@test.com",
// 	"email": "test@test.com",
// 	"firstName": "test",
// 	"lastName": "user",
// 	"phoneNumber": "+25400000023",
// 	"role": "ROLE_STUDENT",
// 	"disabled": false,
// 	"dateOfBirth": "2024-10-06",
// 	"nationality": "Kenyan",
// 	"userInfos": null,
// 	"created_at": 1728186338434,
// 	"updated_at": 1728186338435
