import LayoutView from '../layout/layout.view';
import PanelToggle from '../common/PanelToggle';
import useLayout from '../../hooks/useLayout';
import StudentsPanel from './students.panel';
import useStudents from '../../hooks/useStudents';
import EssayCards from './essay/essay.cards';
import TasksCards from './tasks/tasks.cards';

export default function StudentsView() {
	const { showSidebar } = useLayout();
	const {
		setShowRightPanel,
		showRightPanel,
		handleLimitEssays,
		essays,
		limitEssays,
		tasks,
		handleLimitTasks,
		records,
		handleLimitRecords,
	} = useStudents();
	return (
		<LayoutView>
			<div className="flex flex-row w-full justify-start items-start h-screen">
				{showSidebar === false && (
					<PanelToggle toggle={setShowRightPanel} state={showRightPanel} />
				)}
				<div className="w-full h-full p-4 lg:p-10 mt-8 lg:mt-0 flex flex-col justify-start items-start space-y-10 overflow-y-scroll">
					<h1 className="text-4xl font-semibold">My Students</h1>
					<EssayCards
						handleLimitEssays={handleLimitEssays}
						essays={essays}
						limitEssays={limitEssays}
					/>
					<TasksCards handleLimitTasks={handleLimitTasks} tasks={tasks} />
				</div>
				<StudentsPanel
					showRightPanel={showRightPanel}
					records={records}
					handleLimitRecords={handleLimitRecords}
				/>
			</div>
		</LayoutView>
	);
}
