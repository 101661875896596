import Search from '../common/Search';
import { IoMdNotifications } from 'react-icons/io';
import PropTypes from 'prop-types';

export default function DashboardHeader({ data }) {
	const { name } = data;
	return (
		<div className="flex lg:flex-row flex-col justify-between space-y-8 lg:space-y-0 items-center w-full">
			<div className="flex flex-col items-center lg:items-start space-y-4">
				<h1 className="text-4xl font-semibold">{name}</h1>
				<p className="text-sm">Welcome {name.split(' ')[0]}!</p>
			</div>
			<div className="flex flex-row items-center space-x-4 w-full lg:w-[50%]">
				<Search placeholder="Search here" />
				<IoMdNotifications size="2rem" />
			</div>
		</div>
	);
}

DashboardHeader.propTypes = {
	data: PropTypes.object.isRequired,
};
