import PropTypes from 'prop-types';

export default function AssignmentCard({ assignment }) {
	const { assignment_name, subject_name, created_at, expires_at, total_marks } =
		assignment;
	return (
		<div className="flex flex-col justify-start items-start space-y-9 w-full bg-purple-100 text-purple-500 flex-grow p-4 rounded-[0.75rem]">
			<p className="text-2xl font-medium">{assignment_name}</p>
			<p className="text-xl">{subject_name}</p>
			<div className="flex flex-col justify-start items-start space-y-1">
				<p className="text-md">Created At: {created_at}</p>
				<p className="text-md">Expires At: {expires_at}</p>
				<p className="text-md">Total Marks: {total_marks}</p>
			</div>
		</div>
	);
}

AssignmentCard.propTypes = {
	assignment: PropTypes.shape({
		assignment_name: PropTypes.string.isRequired,
		subject_name: PropTypes.string.isRequired,
		created_at: PropTypes.string.isRequired,
		expires_at: PropTypes.string.isRequired,
		total_marks: PropTypes.number.isRequired,
	}).isRequired,
};
