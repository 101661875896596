const STEPS = [
	{
		step: 0,
		name: 'step0',
	},
	{
		step: 1,
		name: 'step1',
	},
	{
		step: 2,
		name: 'step2',
	},
	{
		step: 3,
		name: 'step3',
	},
	{
		step: 4,
		name: 'step4',
	},
];

const AFFIRMATION_DATA = [
	{
		id: 1,
		value: 'Yes',
	},
	{
		id: 2,
		value: 'No',
	},
];

const APPLICATION_STATUS_CERTAINTY = [
	{
		id: 1,
		value: 'Certain',
	},
	{
		id: 2,
		value: 'Not certain',
	},
	{
		id: 3,
		value: 'No idea',
	},
];

const USER_TYPES = [
	{
		id: 1,
		value: 'Student',
	},
	{
		id: 2,
		value: 'Tutor',
	},
];

export { STEPS, AFFIRMATION_DATA, APPLICATION_STATUS_CERTAINTY, USER_TYPES };
