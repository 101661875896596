import { useEffect, useState } from 'react';
import data from '../components/courses/courses.data';

const useCourses = () => {
	const [assignments, setAssignments] = useState(data.assignments);
	const [showRightPanel, setShowRightPanel] = useState(false);
	const [limitAssignments, setLimitAssignments] = useState(9);
	const [activities, setActivities] = useState(data.activities);

	const handleLimitAssignments = () => {
		if (limitAssignments === 9) {
			setLimitAssignments(assignments.length);
			setAssignments(data.assignments);
		} else {
			setLimitAssignments(9);
			setAssignments(data.assignments.slice(0, 9));
		}
	};

	useEffect(() => {
		const newAssignments = data.assignments.slice(0, limitAssignments);
		setAssignments(newAssignments);
		setActivities(data.activities);
	}, [limitAssignments]);

	return {
		showRightPanel,
		setShowRightPanel,
		assignments,
		handleLimitAssignments,
		activities,
	};
};

export default useCourses;
