import PropTypes from 'prop-types';
import AssignmentCard from './assignment.card';

export default function AssignmentsCards({
	handleLimitAssignments,
	assignments,
}) {
	return (
		<div className="w-full flex flex-col justify-start items-start space-y-16">
			<div className="w-full flex flex-row justify-between items-start">
				<h1 className="text-2xl lg:text-3xl font-semibold">Assignment</h1>
				<button onClick={handleLimitAssignments} className="text-xl">
					{assignments.length === 9 ? 'See All' : 'See Less'}
				</button>
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-3 gap-[2.5rem] w-full">
				{assignments?.map((assignment, index) => (
					<AssignmentCard key={index} assignment={assignment} />
				))}
			</div>
		</div>
	);
}

AssignmentsCards.propTypes = {
	handleLimitAssignments: PropTypes.func.isRequired,
	assignments: PropTypes.array.isRequired,
};
