import PropTypes from 'prop-types';
import Search from '../../common/Search';
import ActivityItem from './activity.item';

export default function ActivityItems({ activities }) {
	return (
		<div className="w-full h-full flex flex-col justify-start items-start space-y-8">
			<div className="w-full flex flex-col justify-start items-start space-y-10 mt-10 lg:mt-0">
				<Search placeholder="Search here" />
				<h1 className="text-2xl lg:text-3xl font-semibold">
					Student Activities
				</h1>
			</div>
			<div className="w-full flex flex-col justify-start items-start space-y-4 h-[90%] overflow-y-scroll">
				{activities?.map((activity, index) => (
					<ActivityItem key={index} activity={activity} />
				))}
			</div>
		</div>
	);
}

ActivityItems.propTypes = {
	activities: PropTypes.array.isRequired,
};
