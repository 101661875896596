const data = {
	assignments: [
		{
			assignment_name: 'Assignment 1',
			subject_name: 'Mathematics',
			total_marks: 100,
			created_at: '01/01/2021',
			expires_at: '01/02/2021',
		},
		{
			assignment_name: 'Assignment 2',
			subject_name: 'Science',
			total_marks: 100,
			created_at: '02/01/2021',
			expires_at: '02/02/2021',
		},
		{
			assignment_name: 'Assignment 3',
			subject_name: 'English',
			total_marks: 100,
			created_at: '03/01/2021',
			expires_at: '03/02/2021',
		},
		{
			assignment_name: 'Assignment 4',
			subject_name: 'History',
			total_marks: 100,
			created_at: '04/01/2021',
			expires_at: '04/02/2021',
		},
		{
			assignment_name: 'Assignment 5',
			subject_name: 'Geography',
			total_marks: 100,
			created_at: '05/01/2021',
			expires_at: '05/02/2021',
		},
		{
			assignment_name: 'Assignment 6',
			subject_name: 'Computer Science',
			total_marks: 100,
			created_at: '06/01/2021',
			expires_at: '06/02/2021',
		},
		{
			assignment_name: 'Assignment 7',
			subject_name: 'Physics',
			total_marks: 100,
			created_at: '07/01/2021',
			expires_at: '07/02/2021',
		},
		{
			assignment_name: 'Assignment 8',
			subject_name: 'Chemistry',
			total_marks: 100,
			created_at: '08/01/2021',
			expires_at: '08/02/2021',
		},
		{
			assignment_name: 'Assignment 9',
			subject_name: 'Biology',
			total_marks: 100,
			created_at: '09/01/2021',
			expires_at: '09/02/2021',
		},
		{
			assignment_name: 'Assignment 10',
			subject_name: 'Physical Education',
			total_marks: 100,
			created_at: '10/01/2021',
			expires_at: '10/02/2021',
		},
		{
			assignment_name: 'Assignment 11',
			subject_name: 'Economics',
			total_marks: 100,
			created_at: '11/01/2021',
			expires_at: '11/02/2021',
		},
		{
			assignment_name: 'Assignment 12',
			subject_name: 'Accounting',
			total_marks: 100,
			created_at: '12/01/2021',
			expires_at: '12/02/2021',
		},
		{
			assignment_name: 'Assignment 13',
			subject_name: 'Business Studies',
			total_marks: 100,
			created_at: '13/01/2021',
			expires_at: '13/02/2021',
		},
		{
			assignment_name: 'Assignment 14',
			subject_name: 'Engineering',
			total_marks: 100,
			created_at: '14/01/2021',
			expires_at: '14/02/2021',
		},
		{
			assignment_name: 'Assignment 15',
			subject_name: 'Design',
			total_marks: 100,
			created_at: '15/01/2021',
			expires_at: '15/02/2021',
		},
		{
			assignment_name: 'Assignment 16',
			subject_name: 'Art',
			total_marks: 100,
			created_at: '16/01/2021',
			expires_at: '16/02/2021',
		},
		{
			assignment_name: 'Assignment 17',
			subject_name: 'Music',
			total_marks: 100,
			created_at: '17/01/2021',
			expires_at: '17/02/2021',
		},
		{
			assignment_name: 'Assignment 18',
			subject_name: 'Dance',
			total_marks: 100,
			created_at: '18/01/2021',
			expires_at: '18/02/2021',
		},
		{
			assignment_name: 'Assignment 19',
			subject_name: 'Theatre',
			total_marks: 100,
			created_at: '19/01/2021',
			expires_at: '19/02/2021',
		},
		{
			assignment_name: 'Assignment 20',
			subject_name: 'Film',
			total_marks: 100,
			created_at: '20/01/2021',
			expires_at: '20/02/2021',
		},
		{
			assignment_name: 'Assignment 21',
			subject_name: 'Photography',
			total_marks: 100,
			created_at: '21/01/2021',
			expires_at: '21/02/2021',
		},
		{
			assignment_name: 'Assignment 22',
			subject_name: 'Journalism',
			total_marks: 100,
			created_at: '22/01/2021',
			expires_at: '22/02/2021',
		},
		{
			assignment_name: 'Assignment 23',
			subject_name: 'Literature',
			total_marks: 100,
			created_at: '23/01/2021',
			expires_at: '23/02/2021',
		},
		{
			assignment_name: 'Assignment 24',
			subject_name: 'Philosophy',
			total_marks: 100,
			created_at: '24/01/2021',
			expires_at: '24/02/2021',
		},
		{
			assignment_name: 'Assignment 25',
			subject_name: 'Psychology',
			total_marks: 100,
			created_at: '25/01/2021',
			expires_at: '25/02/2021',
		},
		{
			assignment_name: 'Assignment 26',
			subject_name: 'Sociology',
			total_marks: 100,
			created_at: '26/01/2021',
			expires_at: '26/02/2021',
		},
		{
			assignment_name: 'Assignment 27',
			subject_name: 'Political Science',
			total_marks: 100,
			created_at: '27/01/2021',
			expires_at: '27/02/2021',
		},
		{
			assignment_name: 'Assignment 28',
			subject_name: 'Anthropology',
			total_marks: 100,
			created_at: '28/01/2021',
			expires_at: '28/02/2021',
		},
		{
			assignment_name: 'Assignment 29',
			subject_name: 'Archaeology',
			total_marks: 100,
			created_at: '01/01/2021',
			expires_at: '01/02/2021',
		},
	],
	activities: [
		{
			student_name: 'Student 1',
			assignment_name: 'Assignment 1',
			marked: true,
			submitted_at: '01/01/2021',
		},
		{
			student_name: 'Student 2',
			assignment_name: 'Assignment 2',
			marked: false,
			submitted_at: '02/01/2021',
		},
		{
			student_name: 'Student 3',
			assignment_name: 'Assignment 3',
			marked: true,
			submitted_at: '03/01/2021',
		},
		{
			student_name: 'Student 4',
			assignment_name: 'Assignment 4',
			marked: false,
			submitted_at: '04/01/2021',
		},
		{
			student_name: 'Student 5',
			assignment_name: 'Assignment 5',
			marked: true,
			submitted_at: '05/01/2021',
		},
		{
			student_name: 'Student 6',
			assignment_name: 'Assignment 6',
			marked: false,
			submitted_at: '06/01/2021',
		},
		{
			student_name: 'Student 7',
			assignment_name: 'Assignment 7',
			marked: true,
			submitted_at: '07/01/2021',
		},
		{
			student_name: 'Student 8',
			assignment_name: 'Assignment 8',
			marked: false,
			submitted_at: '08/01/2021',
		},
		{
			student_name: 'Student 9',
			assignment_name: 'Assignment 9',
			marked: true,
			submitted_at: '09/01/2021',
		},
		{
			student_name: 'Student 10',
			assignment_name: 'Assignment 10',
			marked: false,
			submitted_at: '10/01/2021',
		},
		{
			student_name: 'Student 11',
			assignment_name: 'Assignment 11',
			marked: true,
			submitted_at: '11/01/2021',
		},
		{
			student_name: 'Stuendt 12',
			assignment_name: 'Assignment 12',
			marked: false,
			submitted_at: '12/01/2021',
		},
		{
			student_name: 'Student 13',
			assignment_name: 'Assignment 13',
			marked: true,
			submitted_at: '13/01/2021',
		},
		{
			student_name: 'Student 14',
			assignment_name: 'Assignment 14',
			marked: false,
			submitted_at: '14/01/2021',
		},
		{
			student_name: 'Student 15',
			assignment_name: 'Assignment 15',
			marked: true,
			submitted_at: '15/01/2021',
		},
		{
			student_name: 'Student 16',
			assignment_name: 'Assignment 16',
			marked: false,
			submitted_at: '16/01/2021',
		},
		{
			student_name: 'Student 17',
			assignment_name: 'Assignment 17',
			marked: true,
			submitted_at: '17/01/2021',
		},
		{
			student_name: 'Student 18',
			assignment_name: 'Assignment 18',
			marked: false,
			submitted_at: '18/01/2021',
		},
		{
			student_name: 'Student 19',
			assignment_name: 'Assignment 19',
			marked: true,
			submitted_at: '19/01/2021',
		},
		{
			student_name: 'Student 20',
			assignment_name: 'Assignment 20',
			marked: false,
			submitted_at: '20/01/2021',
		},
		{
			student_name: 'Student 21',
			assignment_name: 'Assignment 21',
			marked: true,
			submitted_at: '21/01/2021',
		},
		{
			student_name: 'Student 22',
			assignment_name: 'Assignment 22',
			marked: false,
			submitted_at: '22/01/2021',
		},
		{
			student_name: 'Student 23',
			assignment_name: 'Assignment 23',
			marked: true,
			submitted_at: '23/01/2021',
		},
	],
};

export default data;
