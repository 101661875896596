import PropTypes from 'prop-types';
import Button from '../../common/Button';
import SelectInputField from '../../common/form/SelectInputField';
import { USER_TYPES } from '../signup.resources';

export default function SignUpForm0({ formik }) {
	return (
		<div className="pb-10 w-full lg:max-w-sm flex flex-col justify-start items-center space-y-[1rem] pt-20">
			<SelectInputField
				label="Joining as"
				id="user_type"
				options={USER_TYPES}
				error={formik.errors.user_type}
				touched={formik.touched.user_type}
				value={formik.values.user_type}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>
			<div className="py-4 w-full">
				<Button onClick={formik.handleSubmit}>Next</Button>
			</div>
		</div>
	);
}

SignUpForm0.propTypes = {
	formik: PropTypes.object.isRequired,
};
