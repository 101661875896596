import LayoutView from '../layout/layout.view';
import PanelToggle from '../common/PanelToggle';
import useLayout from '../../hooks/useLayout';
import CoursesPanel from './courses.panel';
import useCourses from '../../hooks/useCourses';
import AssignmentsCards from './assignments/assignments.cards';

export default function CoursesView() {
	const { showSidebar } = useLayout();
	const {
		setShowRightPanel,
		showRightPanel,
		assignments,
		handleLimitAssignments,
		activities,
	} = useCourses();
	return (
		<LayoutView>
			<div className="flex flex-row w-full justify-start items-start h-full mt-10 lg:mt-0">
				{showSidebar === false && (
					<PanelToggle toggle={setShowRightPanel} state={showRightPanel} />
				)}
				<div className="w-full h-full p-4 lg:p-10 flex flex-col justify-start items-start space-y-10 overflow-y-scroll">
					<AssignmentsCards
						handleLimitAssignments={handleLimitAssignments}
						assignments={assignments}
					/>
				</div>
				<CoursesPanel showRightPanel={showRightPanel} activities={activities} />
			</div>
		</LayoutView>
	);
}
