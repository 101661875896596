import { useLocation } from 'react-router-dom';
import NAV_ITEMS from '../layout.resources';
import NavItem from './menu.item';
import MenuBrand from './menu.brand';
import MenuLogout from './menu.logout';

export default function MobileDesktop() {
	const { pathname } = useLocation();
	const currentRoute = pathname;

	return (
		<div className="hidden lg:flex flex-col justify-between items-center space-y-12 w-1/5 h-full bg-white p-10 lg:drop-shadow-2xl">
			<div className="w-full flex flex-col justify-start items-start space-y-[2.5rem]">
				<div className="w-full flex justify-center items-center">
					<MenuBrand />
				</div>
				<nav className="w-full flex flex-col justify-start items-start space-y-[1.5rem]">
					{NAV_ITEMS.map((nav) => (
						<NavItem key={nav.id} nav={nav} currentRoute={currentRoute} />
					))}
				</nav>
			</div>
			<MenuLogout />
		</div>
	);
}
