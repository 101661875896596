import RandomAvatar from '../common/Avatar';

export default function DashboardAvatar({ name }) {
	return (
		<div className="flex flex-col justify-start items-center space-y-4 w-fill">
			<RandomAvatar size={200} />
			<p className="text-center text-xl">{name}</p>
		</div>
	);
}
